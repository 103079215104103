html, body {
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	height: 100%;
}

.flex {
	display: flex;
	height: 100%;
}

.grow {
	flex-grow: 1;
}

.sidebar {
	width: 35%;
	padding: 1rem;
	height: 100vh;
	background: #19212a;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
}

.sidebar h4 {
	color: #babdc0;
}

h4{
	font-size: none;
}

h5 {
	color: #babdc0;
}

form {
	margin: 10px;
}

textarea {
	color: #333;
	border: 0;
	padding: 0.5rem;
	width: 100%;
	margin: 5px;
	-webkit-box-shadow: none;
	outline: -webkit-focus-ring-color auto 0px;
	border-radius: 3px;
}
input {
	color: #333;
	border: 0;
	padding: 0.5rem;
	width: 100%;
	margin: 5px;
	-webkit-box-shadow: none;
	outline: -webkit-focus-ring-color auto 0px;
	border-radius: 3px;
}
.css-2b097c-container {
    margin: 5px;
}
.right {
	float: right;
}
.bold {
	padding-top: 8px;
	padding-bottom: 8px;
	font-weight: bold;
}
.sidebar ul {
	padding: 0;
	overflow-x: scroll;
	height: 400px;
}

.sidebar li {
	margin-top: 5px;
	list-style: none;
}

.sidebar li .MuiIconButton-root {
	color: #fff;
}

.card-style {
	font-size: 12px;
	background: #363d42;
	border-radius: 0px 4px 4px 0;
	padding: 0 1rem;
}

.card-style h4 {
	padding-top: 10px;
	margin: 0;
	font-size: 14px;
}
.placeholder-text {
    font-family: Arial;
    font-size: 13.3px;
}
.text-muted {
	color: #ccc;
	font-size: 13px;
	padding-left: 28px;
}
.choose-for-me{
    margin: 10px;
}
.material-icons {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
.scroll-window {
    max-height: 100px;
}

.accordion {
  background-color: #eee;
  color: darken(#444);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}
.author{
    float: right;
    text-align: right;
    font-size: 13px;
    color: #444;

}
.active, .accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 10px 18px;
  background-color: white;
  overflow: hidden;
}

.text-body {
    white-space: pre-wrap;
}

.fb-icon{
  position: relative;
  /* Adjust these values accordingly */
  top: 15px;
  margin-left: 5px;
}

.firstHeading {
    font-size: 18px;
}